import React from 'react'

function MobileFooter() {
  return (
    <footer className='mb-footer'>
        The Nigerian Railway Corporation 2024
    </footer>
  )
}

export default MobileFooter