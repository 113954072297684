import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Home from './component/Home';
import  { Helmet } from "react-helmet"
import MobileHome from './mobile/MobileHome';
import Globalprovider from './context/Globalcontext';
import DateDropdown from './DateDropdown.js';
import { useEffect, useState } from 'react';

function App() {
  const [isMobile, setIsmobile] = useState(false);
  let screenWidth = window.innerWidth;
  useEffect(()=>{
    if(screenWidth < 786){
      setIsmobile(true)
    }
  },[screenWidth])
 
  return (
    <>
    <Helmet>
          <script
            src="https://pay-service.icadpay.com/host/new-inline-stage-pay.js"
            // src="https://demo.icadpay.com/host/inline-stage-pay.js"
            // src="https://demo.icadpay.com/host/inline-stage-pay.js"
            async
          ></script>
      </Helmet>
    <Globalprovider>
      <BrowserRouter>
    <Routes>
    <Route path='/' element={isMobile ? <MobileHome/> : <Home/>} />
      <Route path='/mobile' element={<MobileHome/>}/>
      <Route path='/date' element={<DateDropdown/>}/>
    </Routes>
    </BrowserRouter>
    </Globalprovider>
    </>
    
  );
}

export default App;
