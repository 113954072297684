import React, { useContext, useEffect, useState } from 'react';
import Nav from './Nav';
import Footer from './Footer';
import {MdArrowBackIos,MdArrowForwardIos } from "react-icons/md";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Globalcontext } from '../context/Globalcontext';
import ApiInstance from '../txticket_api';
import { BsDisplay } from 'react-icons/bs';

function Home() {
  function dateupdater (event){
    event.target.type = "date";
  }
  const {setActivateShoppingCard,showSelectPassanger, setShowSelectPassenger, setTotalPrice,
     setShoppingCartBasket,
     stations, setStations,selectTripModal, setSelectTripModal, setShoppingCart, setHome,booking, setBooking} =useContext(Globalcontext)
  const [fromstid , setFromstid]= useState(0);
  const [fromData, setFromData] = useState("FROM")
  const [toData, setToData] = useState("TO")
  const [to, setTo]= useState(0);
  const [date, setDate] = useState("");
  const [datestyle, setDateStyle]= useState("date");
  const [fromstyle, setFromstyle]= useState("From");
  const [tostyle, setTostyle] =useState("From");
  const [listcontainer, setlistContainer] = useState(false);
  const [chooseStyle, setChooseStyle] = useState("choose-time");
  const [timeStyle, setTimeStyle] = useState("tim-class");
  const [selectpassenger, setSelectPassenger] = useState("select-passenger");
  const [passengerNum, setPassengerNum] = useState(1);
  const [bookingGo1, setBookingGO1] = useState("goBooking");
  const [homeGo, setHomeGO] = useState("bookingGO");
  const [IsactiveFrom, setIsactiveFrom] = useState(false)
  const [IsactiveTo, setIsactiveTo]= useState(false);
  const [activeGOStyle, setActiveGoStyle]= useState(false)
  const [continueTO,setcontinueTO ]= useState(false)
  const [pleaseCon, setPleaseCon] = useState(false)
  const [NewData, setNewData] = useState()
  const [depTime, setDepTime] = useState("")
  const [ArrTime, setArrTime] = useState("")
  const [TImeTaken, setTimeTaken] = useState("")
  const [firstClassName, setFirstClassName] = useState("")
  const [SecondClassName, setSecondClassName] = useState("")
  const [NameOfTicket, setNameOfTicket] = useState("")
  const [FirstClass, setFirstClass] = useState(0)
  const[passengerStyle, setPassengerStyle] = useState(false)
  
  const [SecondClass, setSecondClass] = useState(0)
  const [productData, setProductData] = useState()
  const [tickets, setTickets] = useState([])
  const [shoppingData, setShoppingData] = useState({
          'prid': "",
          'pvid': "",
          'route': "",
          'centprice': "",
          'id': ""
  })

  const [chooseTime, setChooseTime] = useState(true);
 
  ApiInstance.on_stations_changed = () =>
  {
    let s = ApiInstance.get_stations();
    console.log("stations =", s)
  
   
   
    // console.log(d)
    // let options = s.flatMap((name, stid) => (
    //                 <option value={stid} key={stid}>{name}</option>));
                  
    setStations(s);
  }
  console.log(stations[4]);
    
  function HandleFromChange(e){
    const selectedfrom = + e.target.value;
    const selectedData = stations[selectedfrom];
    // const seletedFrom = e.target.value
    console.log(selectedfrom)
    console.log(selectedData)
    setFromstid(selectedfrom)
    setFromData(selectedData)
    console.log(selectedfrom)
     ApiInstance.set_stid_origin(fromstid)
    // if(from !== ""){
    //    fm = ApiInstance.set_stid_origin(from)
    // }
    setFromstyle("date-update")
    // console.log(typeof(from));
  }
  function HandleToChange(e){
    const selectedIndex = + e.target.value;
    const selectedData = stations[selectedIndex];
    setTo(selectedIndex);
    setToData(selectedData);
    // setTo(parseInt(e.target.value))

    setTostyle("date-update")
    // console.log(typeof(to))
  }

  function handleDateChange(e){
    let dateInput = document.getElementById('dateInput')
    setDate(e.target.value)
      dateInput.style.backgroundColor = 'black';
      dateInput.style.color = 'white';
      dateInput.style.borderColor = '#00f600';
      dateInput.style.textAlign= "center";
      dateInput.style.cursor = "pointer"
  }

  const formatDate = (dateString) => {
    // Splitting the date string into parts
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // month in Date object starts from 0
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  }
 
  useEffect(() => {
    ApiInstance.set_stid_origin(fromstid)
    ApiInstance.set_input_date(date)
    ApiInstance.set_stid_destination(to)
    setIsactiveFrom(false)
    setIsactiveTo(false)
  },[fromstid,date,to])
  useEffect(()=>{
    if(date !=="" && fromstid !== "" && to !==""){
        setHomeGO("bookingGO1")
        setcontinueTO(true)
  }
  },[date, fromstid, to])
  const newDate = formatDate(date)
  // if(date !=="" && from !== "" && to !==""){
  //   let homeGo =document.getElementById("home-go")
  //   homeGo.style.color = "#00f600"
  // }

var ArvTime;
var DptTIme;
  function CheckROutes (){
    ApiInstance.get_products_and_prices(NewData,
      (products) => { console.log("got products: ", products)} );
  }
  async function HandleBooking (){
    if( toData === fromData){
      alert("you can not select the same destination twice")
    }else{
      setIsactiveFrom(false)
      setIsactiveTo(false)
   
   console.log("hello")
   try {
    const routingFinished = ()=>{

    };
    
    
    const routingOptions = (route ,id)=>{
      console.log(route)
      console.log(route.leg)
      setNewData(route)
      var AT =route.leg[0].tod_arr.split(":")
      var DT = route.leg[0].tod_dep.split(":")
   

      setArrTime(AT[0]+":"+AT[1])
      setDepTime(DT[0]+":"+DT[1])
      var arrTimeParts = route.leg[0].tod_arr.split(":");
     var depTimeParts = route.leg[0].tod_dep.split(":");
     ArvTime =parseInt(arrTimeParts[0])
     DptTIme =parseInt(depTimeParts[0])
     console.log(ArrTime, depTime)
     var arrTime = new Date();
     arrTime.setHours(parseInt(arrTimeParts[0], 10));
     arrTime.setMinutes(parseInt(arrTimeParts[1], 10));
     arrTime.setSeconds(parseInt(arrTimeParts[2], 10));
     
     var depTimer = new Date();
     depTimer.setHours(parseInt(depTimeParts[0], 10));
     depTimer.setMinutes(parseInt(depTimeParts[1], 10));
     depTimer.setSeconds(parseInt(depTimeParts[2], 10));
     
     // Calculate the difference in milliseconds
     var differenceMs = arrTime.getTime() - depTimer.getTime();
    //  console.log(differenceMs)
     
     // Convert the difference to hours, minutes, and seconds
     var differenceDate = new Date(differenceMs);
     var hoursDiff = differenceDate.getUTCHours();
     var hoursDiffs = differenceDate.getUTCMinutes();
    
     var ChkATm;
  
     var ChkATs;
    
     if(hoursDiff <10){
       ChkATm = "0"+hoursDiff
     }else{
       ChkATm = hoursDiff
     };
   


     if(hoursDiffs <10){
      ChkATs = "0"+hoursDiffs
     }else{
      ChkATs =hoursDiffs
     }
    
     setTimeTaken(ChkATm + ":"+ ChkATs)
      console.log(hoursDiff)


      ApiInstance.get_products_and_prices(route,
        (products) => { console.log("got products: ", products)
        setFirstClass(products[0].centprice / 100)
        setSecondClass(products[1].centprice / 100)
        setFirstClassName(products[0].name)
        setSecondClassName(products[1].name)
        setProductData(products)
        console.log("first class price :", SecondClass)
        
      }
        
        );
    };

     ApiInstance.start_routing(routingFinished , routingOptions)
     
     
    if(fromData !== "FROM"&& toData !== "TO"){
      setSelectTripModal(false)
    setBooking(true)
    setChooseTime(true)
    setShowSelectPassenger(true)
    }
      
   } catch (error) {
    console.error(error)
   }
   if(newDate !== null){
    CheckROutes()
   }
  }
  }
console.log(window.innerWidth)
console.log(window.innerHeight)

//   const shopping_basketData = ApiInstance.get_shopping_basket()
// const last_ticketData = shopping_basketData.length
// console.log(last_ticketData)
  

  function HandleShowlist(){
    setlistContainer(true)
    setChooseStyle("choose-time-2")
  
  }
  function handleShowChart(){
    setPassengerStyle(true)
    setPleaseCon(true)
    setlistContainer(false)
    setNameOfTicket(firstClassName)
    setShoppingData({
      'prid': productData[1].prid,
      'pvid': productData[1].pvid,
      'route': NewData,
      'centprice': SecondClass * 100,
      'id': productData[1].prid
    })
    setTickets([{
      'prid': productData[1].prid,
      'pvid': productData[1].pvid,
      'route': NewData,
      'centprice': SecondClass * 100,
      'id': productData[1].prid
    }])
    setChooseTime(false)
    setTimeStyle("tim-class-white")
    setSelectPassenger("select-passenger-active")
    setBookingGO1("goBookingActive")
  }
  function Addnumb(){
//     const shopping_basket = ApiInstance.get_shopping_basket()
// const last_ticket = shopping_basket[shopping_basket.length]
// console.log(last_ticket)
// ApiInstance.add_ticket(shoppingData, (data)=>{
//   setTicketData(data)
//   console.log("ticket data = ", data)
//   console.log(data.params)
//   // var getDate = formatDate(data.params.route.leg[0].lxdate)
//   // setTicketDate(getDate)
//    departure = ApiInstance. get_station_name(data.params.route.leg[0].stid_dep)
//    arrival =  ApiInstance. get_station_name(data.params.route.leg[0].stid_arr)
//   //  setDeparture(departure)
//   //  setArrival(arrival)
// console.log(departure, arrival)
// let shoppingBasket =  ApiInstance.get_shopping_basket()
// console.log("your shopping basket:",shoppingBasket);
// setShoppingCartBasket(shoppingBasket)
// let DaTa = ApiInstance.get_shopping_basket_amount() /100
//   setTotalPrice(DaTa)
//   setHome("")
//   setShoppingCart("active")
// // setTotalPrice( ApiInstance.get_shopping_basket_amount())
// let check = ApiInstance.automatic_reservations((ok) => {
// if (ok) {
// setBookingGO1("bookingGO1")
//   // enable "Go" Button, to allow proceeding to payment
// } else {
//   alert("This train is fully booked, please choose another date/time or another class");
//   setActivateShoppingCard(false)
// setBooking(true)


// }
// }
// );
// console.log("check is =", check)
// })
if(passengerNum > 0){
  setTickets((prev)=>[...prev,{
    'prid': productData[1].prid,
    'pvid': productData[1].pvid,
    'route': NewData,
    'centprice': SecondClass * 100,
    'id': productData[1].prid
  }])
  setPassengerNum(passengerNum + 1)

}
}
// Then you can delete the ticket with its id (biunique):



 async function SubtractNum(){
//   const shopping_basket = ApiInstance.get_shopping_baske()
// const last_ticket = shopping_basket[shopping_basket.data.length - 1]

// Then you can delete the ticket with its id (biunique):
if(passengerNum >1){
  setTickets((prev)=>{
    const initialState = [...prev]
    initialState.pop()
    return initialState
  })
  setPassengerNum(passengerNum - 1)
// ApiInstance.del_ticket(last_ticket.biunique)
   
    }else{
    return   alert("there is no ticket left to delete from the shopping_basket!!")
    }
  //   try {
  //     // Perform deletion operation
  //    let result = await ApiInstance.del_ticket(biunique);
  //     alert("Ticket deleted successfully")
  //   console.log("results =",JSON.stringify(result));
  //     // After deletion, reload shopping basket
  //     const shoppingBasket = await  ApiInstance.get_shopping_basket();
      
  //     // Update state with new shopping basket data
  //     setShoppingCartBasket(prevData => prevData.filter(item => item.biunique !== biunique),setTotalPrice( ApiInstance.get_shopping_basket_amount() /100));
  //     // let data = ApiInstance.get_shopping_basket_amount() /100
  
  //     setTotalPrice(prevArray)
      
    
  // } catch (error) {
  //     console.error("Error deleting ticket:", error);
  // }
  }
  function activeBooking(){
    if(SecondClass !== 0 && FirstClass !== 0){
        setShowSelectPassenger(false)
    }
   
   
  }

  const [ticketData, setTicketData] = useState()
  let departure;
  let arrival;
  function ShowCart(){
    tickets.map((ticket,index)=>{
      ApiInstance.add_ticket(ticket, (data)=>{
        setTicketData(data)
        console.log("ticket data = ", data)
        console.log(data.params)
        // var getDate = formatDate(data.params.route.leg[0].lxdate)
        // setTicketDate(getDate)
         departure = ApiInstance. get_station_name(data.params.route.leg[0].stid_dep)
         arrival =  ApiInstance. get_station_name(data.params.route.leg[0].stid_arr)
        //  setDeparture(departure)
        //  setArrival(arrival)
    console.log(departure, arrival)
    if(index + 1 === tickets.length){
      let shoppingBasket =  ApiInstance.get_shopping_basket()
    console.log("your shopping basket:",shoppingBasket);
    setShoppingCartBasket(shoppingBasket)
    setActivateShoppingCard(true)
    setBooking(false)
    } 
    
    let DaTa = ApiInstance.get_shopping_basket_amount() /100
        setTotalPrice(DaTa)
        setHome("")
        setShoppingCart("active")
    // setTotalPrice( ApiInstance.get_shopping_basket_amount())
   let check = ApiInstance.automatic_reservations((ok) => {
    if (ok) {
      setBookingGO1("goBookingActive")
        // enable "Go" Button, to allow proceeding to payment
    } else {
        alert("This train is fully booked, please choose another date/time or another class");
        setActivateShoppingCard(false)
        setBookingGO1("goBooking")
      setBooking(true)
     
     
    }
  }
  );
   console.log("check is =", check)
      })
    })
   
 
    // setActivateShoppingCard(true)
    // setBooking(false)
  }
  function HandleFirstClass(){
    setPassengerStyle(true)
    setPleaseCon(true)
    setlistContainer(false)
    setlistContainer(false)
    setNameOfTicket(SecondClassName)
    setShoppingData({
      'prid': productData[0].prid,
      'pvid': productData[0].pvid,
      'route': NewData,
      'centprice': FirstClass * 100,
      'id': productData[0].prid
    })
    setTickets([{
      'prid': productData[0].prid,
      'pvid': productData[0].pvid,
      'route': NewData,
      'centprice': FirstClass * 100,
      'id': productData[0].prid
    }])
    setChooseTime(false)
    // setSelectTripModal(true)
    setTimeStyle("tim-class-white")
    setSelectPassenger("select-passenger-active")
    setBookingGO1("goBookingActive")
    
  }
 
  return (
    <div className='home'>
       <Nav/> 
       <div className='home-container'>
        <div className='cont-1'>
         <div className='cont-1-sec'>
         <p className='welcome'>Welcome To Your <br />
          Ticketing Platform</p>

        <h2>Port Harcourt - Aba</h2>
        <div>
          <h3 className='nrc-name'>NRC</h3>
          <p className='nrc'>Nigerian Railway Corporation</p>
        </div>
         </div>
        </div>
        <div className='cont-2'>
            <div className='content'>
            {selectTripModal?  <div >
                
              <div className='select-trip'>PLEASE  SELECT  YOUR  TRIP</div>
                <div className='selection'>
                       {/* <select  className={fromstyle} onChange={(e)=>HandleFromChange(e)}>
                       <option className='option-from' value="" >F R O M</option>
                  {stations.map((data, index)=>(
                       <option key={index} className='option-from' value={index} >{data}</option>

                      
                       ))}
                     </select> */}

                      <div className={fromstyle}  onClick={(e)=> setIsactiveFrom(!IsactiveFrom)
                      
                      }>
                        <div className="dropdownTItle">{fromData}</div>
                        {IsactiveFrom ===true? (
                             <div className="dropdownContent"
                             >
                              {stations.map((data, index)=>(
                                 <div className="dropdownItem " key={index}  ><option value={index} onClick={(e)=>{HandleFromChange(e);
                                  setIsactiveFrom(!IsactiveFrom)
                                 }

                                 
                               }>{data}</option></div>
                              ))}
                             </div> 
                        ):null}
                     
                      </div>

               
                {/* <select className={tostyle} name="" value={to} onChange={(e)=>HandleToChange(e)}>
                  <option className='option-from' value="">to</option>
                  {stations.map((data, index)=>(
                       <option key={index} className='option-from' value={index}  >{data}</option>
                       ))}
                </select> */}

                        
                <div className={tostyle}  onClick={()=> setIsactiveTo(!IsactiveTo)}>
                      <div className="dropdownTItle">{toData}</div>
                      {IsactiveTo ===true? (
                           <div className="dropdownContent"
                           >
                            {stations.map((data, index)=>(
                               <div className="dropdownItem " key={index}  ><option value={index} onClick={(e)=>{HandleToChange(e);
                                setIsactiveTo(!IsactiveTo)
                               }

                               
                             }>{data}</option></div>
                            ))}
                           </div> 
                      ):null}
                   
                    </div>

                <input type="text" className={datestyle} placeholder='Date' id='dateInput' value={date} onChange={(e)=>handleDateChange(e)} onFocus={dateupdater}/>
                </div>
                <div className='sel'>
               {continueTO? <h4 className='sect-go-text'>Please click "GO" to continue</h4>:null}
                <div className='sect-go'>
                <h1 id={homeGo}  onClick={HandleBooking}>GO</h1>

                </div>
                
            </div>
              </div>:null}
            {booking?  <div>
                <div className='booking-home-dkt'><p className='booking-dkt'>Booking </p><p className='booking-dkt'>&gt;</p> <p>Shopping Cart</p><p>&gt;</p> <p>Buy</p><p>&gt;</p> <p>Ticket</p></div>
                <div className='booking-container'>
                  <h2 className='trip-title'>TRIP</h2>
                  <p className='from-to-booking'>{fromData} -&gt; {toData}</p>
                  <h3 className='bookingDate' >DATE</h3>
                  <p className='booking-newDate' >{newDate}</p>
                   <h2 className={timeStyle}>TIME AND CLASS</h2>
                  
                  {chooseTime?<span className={chooseStyle} onClick={HandleShowlist}>Please choose your time?</span>: <p style={{fontSize:"17px", marginBottom:"5px", marginTop:"-5px"}}> {passengerNum +" "+ "x" + " " + " "}{NameOfTicket} {depTime} -&gt; {ArrTime}</p>}
                  {listcontainer?<div className='choose-time-container'>
                    <div className='choose-time-title'><p>DEP.</p>
                    <p>TIME</p>
                    <p>ARRIV.</p>
                    <p>Second class</p>
                    <p>First class</p></div>
                    <div className='container-list-trv'>
                      <div className='flex travel-time-cont'>
                        <div className=''>
                          <div className='flex travel-time'><p className='pick-tim' >{depTime}{DptTIme <12?<span>am</span>:<span>pm</span>}</p> <p>{TImeTaken}</p> <p className='pick-tim'>{ArrTime}{ArvTime <12?<span>am</span>:<span>pm</span>}</p></div>
                         <div  className='travel-arrow'> <p> &#x2192;</p></div></div> <div className='flex'><button onClick={handleShowChart} style={{marginRight:"5px"}}>N {SecondClass}</button> <button onClick={HandleFirstClass}>N {FirstClass}</button></div>
                      </div>
                    </div>              
                  </div>:null}
                 
                 {showSelectPassanger? <div> <h2 className={passengerStyle?"tim-class-1":'tim-class-3'}>PASSENGERS</h2>
                 <span className={selectpassenger}><p onClick={Addnumb} style={{cursor:"pointer", fontSize:"28px",}}>+</p> <p>{passengerNum}</p> <p onClick={SubtractNum}  style={{cursor:"pointer", fontSize:"28px",}}>-</p></span>
                 </div>:<div><h6 className='type-sec'>TYPE</h6> <p className='setting-policy'>Free Setting Policy</p>
                 <p className='to-proceed'>Please click „<span className='miniGO'>GO</span>“ to proceed to the„Shopping Cart“.
You can delete and add tickets in the shopping cart.</p>
                 </div>}
                </div>
                {showSelectPassanger ?    <div className='sel sectAc2'>
                <h4 className='sect-go-text selGO-bk2 '>{pleaseCon?`Please click "GO" to continue`:null}</h4>
                <div  className='select-trip-go-container select-trip-go-container-activ'><button id='prev-btn-shopping-chart'onClick={()=>{setBooking(false)
                 setIsactiveTo(false)
                 setIsactiveFrom(false)
                setSelectTripModal(true)}}><MdArrowBackIos /></button>
                <div  className='sect-go-booking'>

                <h1 id={bookingGo1} onClick={activeBooking}>GO</h1>
                </div>
                
                  <button><MdArrowForwardIos /></button></div>
                
                
            </div>:
            
            
            
            
            
            <div className='sel sectAc2'>
            <h4 className='sect-go-text selGO-bk2 '>{pleaseCon?`Please click "GO" to continue`:null}</h4>
                <div className='select-trip-go-container select-trip-go-container-activ'
                ><button id='prev-btn-shopping-chart' onClick={()=>{setShowSelectPassenger(true);
                setIsactiveTo(false)
                setIsactiveFrom(false)

                   setlistContainer(true)}}><MdArrowBackIos /></button>
                   <div className='sect-go-booking'>

                     <h1 id={bookingGo1} onClick={ShowCart}>GO</h1> 
                   </div>
                     <button><MdArrowForwardIos /></button></div>
               
                
            </div>}

              </div>:null}
           
            </div>
            
        </div>
       </div>
       <Footer/>
    </div>
  )
}

export default Home