import React, { useState } from 'react';
import { MdDehaze } from "react-icons/md";

export default function MobileNav() {
  const [mobileModal, setMobileModal] = useState(false)
  const [shoppingModal, setShoppingModal]= useState(false)
  const [informationModal, setinformationModal]= useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [signinModal, setsigninModal] = useState(false)
  const [home, setHome] = useState("active");
  const [shoppingCart, setShoppingCart] =useState("");
  const [information, setInformation] = useState("");
  const [purchaseHistory, setPurchaseHistory] = useState("");
  const [signIn, setSignIn]= useState("")
  function activateModal (){
    setMobileModal(true)
  }
  function deactivateModal(){
    setMobileModal(false)
  }
  function ActivateHome(){
    if(home !== "active"){
      setHome("active")
      setMobileModal(false)
    setInformation("")
    setPurchaseHistory("")
    setSignIn("")
    setShoppingCart("")
    }
  }
  function ActivateShoppingCart(){
    if(shoppingCart !=="active"){
      setShoppingCart("active")
      setShoppingModal(true)
      setMobileModal(false)
      setHome("")
      setInformation("")
      setPurchaseHistory("")
      setSignIn("")
    }
  }
  function ActivateInformation(){
    if(information !=="active"){
      setInformation("active")
      setinformationModal(true)
      setMobileModal(false)
    setShoppingCart("")
    setHome("")
    setPurchaseHistory("")
    setSignIn("")
    } 
  }
  function ActivatePurchaseHistory(){
    if(purchaseHistory !== "active"){
      setPurchaseHistory("active")
      setPurchaseModal(true)
      setMobileModal(false)
    setInformation("")
    setShoppingCart("")
    setHome("")
    setSignIn("")
    } 
  }
  function ActivateSignIn(){
    if(signIn !== "active"){
      setSignIn("active")
      setsigninModal(true)
      setMobileModal(false)
    setPurchaseHistory("")
    setInformation("")
    setShoppingCart("")
    setHome("")
    }
  }

  function cancelShoppingModal(){
    setShoppingModal(false)
  }
  function cancelInformationModal(){
    setinformationModal(false)
  }
  function cancelPurchaseModal(){
    setPurchaseModal(false)
  }
  function cancelSiginModal(){
    setsigninModal(false)
  }
  return (
    <nav className='mobileNav'>
        <div><img src="WhatsApp_Image_2024-02-27_at_18.48.01_3d5745bc-removebg-preview.png" alt=""  width={43}/></div>
        <div className='headername'>NRC</div>
        <div>
        <MdDehaze className='hamburger' onClick={activateModal} />
      {mobileModal?   <div className='nav-modal'>
          <ul>
            <li onClick={ActivateHome} style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className={home}>Home</p>   <p style={{cursor:"pointer"}} onClick={deactivateModal}>X</p></li>
            <li onClick={ActivateShoppingCart} className={shoppingCart}>Shopping Cart</li>
            <li onClick={ActivateInformation} className={information}>Information</li>
            <li onClick={ActivatePurchaseHistory} className={purchaseHistory}>Purchase History</li>
            <li onClick={ActivateSignIn} className={signIn}>Sign in / Sign up</li>
          </ul>
        </div>:null}
       {shoppingModal? <div className='shopping-cart-mb'>
          <div><h4>Shopping Cart</h4> <h3 onClick={cancelShoppingModal}>X</h3></div>
          <p>There are currently no ticket in your shopping cart.</p>
          <p>Please book a ticket or sign in to your account for more information.</p>
        </div>:null}
       {informationModal? <div className='information-mb'>
          <div>
            <h4>Infromation</h4> <h3 onClick={cancelInformationModal}>X</h3>
          </div>
          <p>Welcome to the official Port Harcourt -
            Aba e-ticketing platform provided by the
            Nigerian Railway Corporation in
            cooperation with its technical partners.    
            </p>
            <p>We are constantly working to improve our
              service for you and will therefore update
              this platform on regular intervals.
            </p>
            <p>
            Please note that this application features
            free seating only on all journeys due to
            NRC’s policy.
            </p>
            <p>
            For more information please visit the
            official NRC website https://nrc.gov.ng .
            </p>
            <p>We hope you will enjoy a smooth booking
              process with this application and wish you
              a pleasant trip.
            </p>
        </div>:null}
      {purchaseModal?   <div className='purchase-mb'>
    <div><h4>Purchase History</h4> <h3 onClick={cancelPurchaseModal}>X</h3></div>
      <p>There are currently no tickets in your
        purchase history.
        </p>
        <p>Please buy a ticket or sign in to your
            account for more information.
          </p>
          <p>When purchasing a ticket without an
            account any ticket information will only be
            stored temporarily on this application.
          </p>
        </div>:null}
        {signinModal?<div className='sign-in-mb'>
          <div>
            <h4>Sign in</h4> <h3 onClick={cancelSiginModal}>X</h3>
          </div>
          <p className='pls-sign-in-mb'>Please sign into your account with your
            email address and password.
            </p>
            <form action="" className='sign-in-mb-form'>
             <div>
             <label htmlFor="">Please type in your email</label>
              <input type="text" placeholder='your email'/>
             </div>
             <div>
             <label htmlFor="">Please type in your passowrd</label>
              <input type="text" placeholder='your password'/>
             </div>
             <button>Sign in</button>
            </form>
            <p>Please click here If you do not have an
              account yet.
              </p>
              <button className='register-mb'>Register an account?</button>
              <p>
              Please click here If you cannot log into
              your account.
              </p>
              <button className='register-mb'>Cannot log in?</button>
        </div>:null}
        </div>
    </nav>
  )
}
