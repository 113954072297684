import React from 'react'
import MobileNav from './MobileNav'
import Content from './Content';
import MobileFooter from './mobileFooter';
import Booking from './Booking';

function MobileHome() {
  return (
    <div className='mobileHome'>
        <MobileNav/>
        <div className='headings'>
          <p className='titl-1'>Port Harcourt to Aba</p>
          <p className='titl-2'>Welocome To Your Ticketing Platform</p>
          </div>
          {/* <Content/> */}
          <Booking/>
       <MobileFooter/>
    </div>
  )
}

export default MobileHome;