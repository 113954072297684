import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
// import './CalendarButton.css'; // Import your CSS file for styling

const CalendarButton = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDateChange = (dates) => {
    setSelectedDate(dates[0]);
    setIsModalOpen(false);
  };

  return (
    <div className="calendar-button-container">
      <button onClick={() => setIsModalOpen(true)}>Select Date</button>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <Flatpickr
              onChange={handleDateChange}
              options={{
                inline: true,
              }}
            />
          </div>
        </div>
      )}
      {selectedDate && (
        <p>Selected Date: {selectedDate.toLocaleDateString()}</p>
      )}
    </div>
  );
};

export default CalendarButton;
