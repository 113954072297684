import { useState, createContext } from "react";
export const Globalcontext = createContext();

function Globalprovider(props){
const [activateShoppingCard, setActivateShoppingCard] = useState(false);
const [showSelectPassanger, setShowSelectPassenger] = useState(true);
const [selectTripModal, setSelectTripModal] = useState(true);
const [booking, setBooking] = useState(false);
// const [departure, setDeparture] = useState();
// const [arrival, setArrival] = useState();
const [ticketDate, setTicketDate] = useState()
const [shoppingCartBasket, setShoppingCartBasket] =useState([])
const [stations, setStations] = useState([]);
const [TotalPrice , setTotalPrice] = useState(0)
const [showPay, setShowPay] = useState(false)
const [chooseTime, setChooseTime] = useState(true);
const [shoppingCart, setShoppingCart] = useState("");
const [home, setHome] = useState("active");



    return<Globalcontext.Provider value={{activateShoppingCard, setActivateShoppingCard, showSelectPassanger,
         setShowSelectPassenger,ticketDate, setTicketDate, shoppingCartBasket, setShoppingCartBasket,
         stations, setStations,TotalPrice , setTotalPrice,selectTripModal, setSelectTripModal,booking, setBooking,chooseTime, setChooseTime,
         showPay, setShowPay,shoppingCart, setShoppingCart,home, setHome}}>{props.children}</Globalcontext.Provider>
}
export default Globalprovider;