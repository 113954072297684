import React, { useState } from 'react'
import {MdArrowBackIos,MdArrowForwardIos } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

function Booking() {
  const [passegersNumb, setPassengersNumb] = useState(1)
  function HandleIncrease(){
    setPassengersNumb(passegersNumb + 1)
  }
  function HandleDecrease(){
    if(passegersNumb > 1){
      setPassengersNumb(passegersNumb - 1)
    }
    if(passegersNumb === 1){
      return false
    }
  }
  return (
    <div className='booking-mb'>
        <div className='booking-mb-title'>
            <p style={{color:"white"}}>Booking </p><p>
                <MdArrowForwardIos className='arrow-left' /></p>
             <p>Shopping Cart</p> 
             <p> <MdArrowForwardIos className='arrow-left' /> </p>
             <p>Buy </p> 
             <p><MdArrowForwardIos className='arrow-left' /></p>
             <p>Ticket</p>
        </div>
        <div className='booking-mb-container'>
        <h2 className='mb-trip'>TRIP</h2>
       <div className='mb-trip-detail'> <p>Aba</p> <p><MdOutlineKeyboardDoubleArrowRight /></p> <p>Konkon</p></div>
       <h2 className='mb-date'>Date</h2>
       <p>Sunday 10th February 2024</p>
       <div className='time-class-mb'>
       <label htmlFor="">TIME AND CLASS</label>
       <button>Please Choose your time</button>
       <div className='time-class-mb-table'>
        <table>
         <tr>
          <th>DEP.</th>
          <th>TIME</th>
          <th>ARRIV</th>
          <th>Second</th>
          <th>First</th>
          <th></th>
         </tr>
         <tr>
          <td>15:00</td>
          <td>01:15</td>
          <td>16:15</td>
          <td>N 200</td>
          <td>N 500</td>
         </tr>

        </table>
       </div>
       </div>
       <div className='time-class-mb'>
       <label htmlFor="">Passengers</label>
       <div className='add-passagers-mb'> <div className='decrease-btn' onClick={HandleDecrease}>-</div> {passegersNumb} <div className='increase-btn' onClick={HandleIncrease}>+</div></div>
       </div>
        </div>
        <div className='sel-mb'>
                <h4 className='sect-go booking-bm-go'>Please click "GO" to continue</h4>
                <div className='go-options'> <button className='prev-btn-mb'> <MdArrowBackIos /> </button><h1 id='go-mb' className='sect-go'>GO</h1> <button><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
    </div>
  )
}

export default Booking