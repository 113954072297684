import React, { useContext, useEffect, useRef, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import {MdArrowBackIos,MdArrowForwardIos } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { Globalcontext } from "../context/Globalcontext";
import ApiInstance from '../txticket_api';
import QRCode from "react-qr-code"

function Nav() {
  const QrcodeRef = useRef(null)
  const {activateShoppingCard, setActivateShoppingCard,
    setShowSelectPassenger,shoppingCartBasket,setShoppingCartBasket,TotalPrice ,setTotalPrice,
    stations,setSelectTripModal, setBooking,showPay, setShowPay,setChooseTime,shoppingCart, setShoppingCart,home, setHome} =useContext(Globalcontext)
  const [shoppingModal, setShoppingModal] = useState(false);
  const [informationModal, setinformationModal] = useState(false);
  const [showSignin, setShowsignin] = useState(true);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [showNin, setShowNin]= useState(false)
  const [signinModal, setsigninModal] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [loginSuccesfully, setLoginSuccessfully] = useState(false);
  const [information, setInformation] = useState("");
  const [purchaseHistory, setPurchaseHistory] = useState("");
  const [signIn, setSignIn] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [error, setError] = useState(false);
  const [registerBtn, setregisterBtn] = useState("go-reg")
  const [gobtnSigninz, setGobtnSigninz] =useState("sect-goCart")
  const [goNinbtn, setgoNinbtn] = useState("go-purchase")
  const [goForgottenpwd, setGoforgottenpwd] =useState("go-purchase")
  const [registerError, setRegisterError] = useState(false);
  const [ninError, setNInError] = useState(false);
  const [forgotpwdinput, setForgotpwdinput]= useState("")
  const [successNin, setSuccessNin] = useState(false)
  const [failedNin, setfailedNin] = useState(false)
  const [BuyTicket, setBuyticket] = useState(false)
  const [forgotpasswordModal, setforgetpassowrdModal]=useState(false)
  const [showChangeemailsucc, setshowChangeemailsucc]= useState(false);
  const [goBtnshoppingNin, setGoBtnShoppingNin] = useState("sect-go1")
  const [showSingletTicket, setShowSingleTicket] = useState(false)
  const [withAccStyle, setWithAccStyle] = useState(true)
  const [GoshoppingCartStyle, setGoshoppingCartStyle] =useState(true)
  const [payoutGo, setpayoutGO] = useState("sect-go")
  const [withoutReg, setwithoutReg] = useState(true)
  const [chooseIcad, setchooseIcad] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [ispleaseContinue, setIspleaseContinue] = useState(false)
  const [TransactionREf, setTransactionREf] = useState("")
  const [purchasedticket, setpurchacedTicket] = useState(null)
  const [paymentProcessing, setPaymentProcessing] = useState(false)
  const [successfulPurchase, setSuccessfulPurchase] = useState(false)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  const [shoppingChartNin, setShoppingChartNin] = useState({
    nin:"",
    email:"",
    confirmEmail:"",
  })
  const [paymentData, setPaymentData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    confirmEmail:"",
    amount:ApiInstance.get_shopping_basket_amount() /100,
    naration:`NRC - ticket purchase `,
    Ref:"",
    callBack:""
  });
  const [registerUserData, setRegisterUserData] = useState({
    title: "",
    firstName: "",
    surName: "",
    No: "",
    road: "",
    city: "",
    state: "",
    country: "",
    telephone: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });
  const [ninDetails, setNinDetails] = useState("")
  function ActivateHome() {
    if (home !== "active") {
      setSelectTripModal(true)
      setBooking(false)
      setActivateShoppingCard(false)
      setinformationModal(false);
      setPurchaseModal(false)
      setHome("active");
      setInformation("");
      setPurchaseHistory("");
      setSignIn("");
      setShoppingCart("");
    }
  }

  function ActivateShoppingCart() {
    if (shoppingCart !== "active") {
      setShoppingCart("active");
      setSelectTripModal(true)
      setPurchaseModal(false)
      setsigninModal(false)
      setBooking(false)
      setHome("");
      setwithoutReg(true)
      setWithAccStyle(true)
      setGoshoppingCartStyle(true)
      // setBooking(false)
      // setShoppingModal(true)
      setActivateShoppingCard(true)
      let shoppingBasket =  ApiInstance.get_shopping_basket()
      setShoppingCartBasket(shoppingBasket)
      let data = ApiInstance.get_shopping_basket_amount() /100
      setTotalPrice(data)
      setCartTotalPrice(data)
      setInformation("");
      setPurchaseHistory("");
      setSignIn("");
    }
  }
  function ActivateInformation() {
    if (information !== "active") {
      setInformation("active");
      setinformationModal(true);
      setSelectTripModal(true)
      setPurchaseModal(false)
      setActivateShoppingCard(false)
      // setS
      setsigninModal(false)
      setBooking(false)
      setShoppingCart("");
      setHome("");
      setPurchaseHistory("");
      setSignIn("");
    }
  }
    // if(purchasedticket.ticketlist[0].qrcode){
    //   QrCodeData.innerHTML =purchasedticket.ticketlist[0].qrcode
    //  }
  function ActivatePurchaseHistory() {
    if (purchaseHistory !== "active") {
      setPurchaseHistory("active");
      setInformation("");
      setSelectTripModal(true)
      setPurchaseModal(true)
      setsigninModal(false)
      setinformationModal(false);
      setActivateShoppingCard(false)
      setBooking(false)
      setShoppingCart("");
      setHome("");
      setSignIn("");
     let ticket = localStorage.getItem("purchasedTicket");
     setpurchacedTicket(JSON.parse(ticket))
    //  if(purchasedticket.ticketlist[0].qrcode){
    //   QrCodeData.innerHTML =purchasedticket.ticketlist[0].qrcode
    //  }
    // //  QrCodeData.innerHTML = purchasedticket.ticketlist[0].qrcode
    
     console.log(purchasedticket);
    }
  }
  function ActivateSignIn() {
    if (signIn !== "active" && isLoggedIn ===false) {
      setSignIn("active");
      setActivateShoppingCard(false)
      setsigninModal(true);
      setShowsignin(true)
      setShowRegister(false)
      setinformationModal(false);
      setLoginSuccessfully(false)
      setActivateShoppingCard(false)
      setPurchaseModal(false)
      setPurchaseHistory("");
      setInformation("");
      setShoppingCart("");
      setHome("");
    }
    if(isLoggedIn === true){
      setSignIn("active");
      setIsLoggedIn(false)
      setSignIn(true)
      setShoppingCart("");
    }
    
    console.log(signinModal);
  }
  function cancelInformationModal() {
    setinformationModal(false);
  }
// function checkBasket(){
//   let basket = ApiInstance.get_shopping_basket()
//   setShoppingCartBasket(basket)
// }
let dataa =ApiInstance.get_shopping_basket_amount()
console.log(dataa)
// function updatePrice(){
//   let DaTa = ApiInstance.get_shopping_basket_amount() /100
//    setTotalPrice(DaTa)
//    setCartTotalPrice(DaTa)
// }
// const prevArray = useRef(TotalPrice);

const SessionSateData = ApiInstance.get_sessionstate()
// let go = document.getElementById("go-btn");
useEffect(() => {
  // localStorage.clear()
  // checkBasket()
 
  if(SessionSateData === "PURCHASED" && paymentProcessing === true){
    setPaymentProcessing(false)
    setSuccessfulPurchase(true)
    ApiInstance.get_last_purchase((purchase)=>{
      setpurchacedTicket(purchase)
      localStorage.setItem('purchasedTicket',JSON.stringify(purchase));
      document.getElementById("qrCode").innerHTML =purchasedticket.ticketlist[0].qrcode
      console.log("ticket purchase =", purchase)
      setTimeout(()=>{
        setSuccessfulPurchase(false)
        setShowSingleTicket(true)
      },3000)
    })}
  
  if(paymentData.firstName !==""&& paymentData.lastName !== "" && paymentData.confirmEmail !== "" ){
    setpayoutGO("sect-go2")
  }
  // if (TotalPrice !== prevArray.current) {
  //   // setArrayChanged(true);
  //   let data = ApiInstance.get_shopping_basket_amount() /100
  //     setTotalPrice(data)
  //     console.log(TotalPrice);
  // }
    // ApiInstance.cancel_payment("icadpay", paymentData.Ref);
    
    // shoppingCartBasket
    // let registerBtn = document.getElementById('go-reg-active');
  // let fillData =document.getElementById("fill-data")
  // let fillNin = document.getElementById('fill-nin')
  if (userEmail.trim() !== "" && userPassword.trim() !== "") {
    setGobtnSigninz("sect-goactive");
  }

  if (
    registerUserData.title !== "" &&
    registerUserData.firstName !== "" &&
    registerUserData.surName !== "" &&
    registerUserData.No !== "" &&
    registerUserData.city !== "" &&
    registerUserData.road !== "" &&
    registerUserData.state !== "" &&
    registerUserData.country !== "" &&
    registerUserData.telephone !== "" &&
    registerUserData.email !== "" &&
    registerUserData.confirmEmail !== "" &&
    registerUserData.password !== "" &&
    registerUserData.confirmPassword !== ""
  ){
    setregisterBtn("go-reg-active")
    // registerBtn = "go-reg-active"
    // fillData.style.color = "black"
    // fillNin.style.color = "white"
  } 

  
  if (ninDetails !== "") {
   setgoNinbtn("go-purchase-active")
  }
  if(forgotpwdinput !== ""){
    setGoforgottenpwd("go-purchase-active")
  }

  // if(shoppingChartNin.confirmEmail ==="" && shoppingChartNin.nin ==="" && shoppingChartNin.email ===""){
  //   setGoBtnShoppingNin('sect-go1')
  // }

}, [userEmail, userPassword,shoppingChartNin.nin,shoppingChartNin.email,shoppingChartNin.confirmEmail,registerUserData.title,registerUserData.firstName,registerUserData.surName,registerUserData.No,registerUserData.road,registerUserData.state,ninDetails
 ,forgotpwdinput, registerUserData.country,registerUserData.telephone, registerUserData.email,TotalPrice, registerUserData.city,registerUserData.confirmEmail,registerUserData.password,registerUserData.confirmPassword,
 paymentData.firstName,paymentData.lastName,paymentData.email,paymentData.confirmEmail,SessionSateData, 
]);

// useEffect(()=>{
//   let DaTa = ApiInstance.get_shopping_basket_amount() /100
//   setTotalPrice(DaTa)
//   setCartTotalPrice(DaTa)
// },[shoppingCartBasket])

useEffect(()=>{
  if(showSingletTicket && window.localStorage ){

    let ticket = localStorage.getItem("purchasedTicket");
    let ticketdata = JSON.parse(ticket)
    console.log("ticketdata=", ticketdata);
    console.log("QrCodeData =", QrcodeRef.current);
    // setpurchacedTicket(JSON.parse(ticket))
    if( QrcodeRef.current &&(ticketdata &&  ticketdata.ticketlist &&  ticketdata.ticketlist[0].qrcode )){
        QrcodeRef.current.innerHTML =ticketdata.ticketlist[0].qrcode
        console.log("ticketdata.ticketlist[0].qrcode =",ticketdata.ticketlist[0].qrcode)
       }
  }
},[QrcodeRef.current,showSingletTicket])
  // if (userEmail.trim() !== "" && userPassword.trim() !== "") {
  //   setGobtnSigninz("go-purchase-active")
  //   // go.style.color = "#00f600";
  //   // go.style.cursor = "pointer";
  // }

  function handleSend() {
    console.log("hello");
    let input1 = document.getElementById("input1");
    let input2 = document.getElementById("input2");
   
    if (input1.value.trim() === "") {
      setError(true);
    }
    if (input2.value.trim() === "") {
      setError(true);
      input2.setAttribute("required", "true");
    }
    setIsLoggedIn(true)
    setLoginSuccessfully(true);
    setShowsignin(false);
    if(withAccStyle === false ){
      setLoginSuccessfully(true)
      setTimeout(() => {
        setLoginSuccessfully(false)
        setBuyticket(true)
      setsigninModal(false)
      }, 1000);
      
    }else{
      setTimeout(()=>{
        setLoginSuccessfully(false)
        setSelectTripModal(true)
        setSignIn("")
        setHome("active")
        setIsLoggedIn(true)
        setShowsignin(false);
        setsigninModal(false)
      },3000)
    }
    
  }
  function HandleRegister() {
    setShowsignin(false);
    setShowRegister(true);
  }

  function closeSignin() {
    setsigninModal(false);
    setSelectTripModal(true)
    setHome("active");
    setSignIn("");
  }
  
  function HandleRegisterUser() {
    if (
      registerUserData.title !== "" &&
      registerUserData.firstName !== "" &&
      registerUserData.surName !== "" &&
      registerUserData.No !== "" &&
      registerUserData.city !== "" &&
      registerUserData.road !== "" &&
      registerUserData.state !== "" &&
      registerUserData.country !== "" &&
      registerUserData.telephone !== "" &&
      registerUserData.email !== "" &&
      registerUserData.confirmEmail !== "" &&
      registerUserData.password !== "" &&
      registerUserData.confirmPassword !== ""
    ){
      setShowNin(true);
      setShowRegister(false);
    }else{
      setRegisterError(true);
    }
  }

  function HandleNIn(){
   
    if(ninDetails === ""){
      setNInError(true)
    }
  if(ninDetails !== ""){
    setShowNin(false)
    setSuccessNin(true)
  }
  }
  function handleForgottenPassword(){
    setShowsignin(false);
    console.log(showNin)
    setforgetpassowrdModal(true)
  }

  function handlefogottenpwdBtn(){
    if(forgotpwdinput === ""){
      setError(true)
    }else{
      setError(false)
     setforgetpassowrdModal(false)
     setshowChangeemailsucc(true)
    }
  }

  function cancelPurchaseModal(){
    setPurchaseModal(false);
    setHome("active");
    setPurchaseHistory("");
  }
  function cancelshoppingChartModal(){
    setActivateShoppingCard(false)
    setSelectTripModal(true)
    setChooseTime(true)
    setShoppingModal(false);
    setHome("active");
    setShoppingCart("");
    setSignIn("")
    setBuyticket(false)
    setWithAccStyle(true)
    setwithoutReg(true)
  }
  function WithoutRegistering(){
    if(withoutReg === false){
      // setwithoutReg(false)
    setActivateShoppingCard(false)
    setsigninModal(false)
    // setsigninModal(true)
    setBuyticket(true)
    }

    if(isLoggedIn === false && withoutReg === true){
      setActivateShoppingCard(false)
      setsigninModal(true)
      setShoppingCart("");
      setSignIn(true)
      setLoginSuccessfully(false)
    setSignIn("active")
    }
    if(isLoggedIn === true){
      setBuyticket(true)
      setsigninModal(false)
    }
    
  }
  function returnBack1(){
    setActivateShoppingCard(true)
    setBuyticket(false)
    setwithoutReg(true)
     setWithAccStyle(true)
     setGoshoppingCartStyle(true)
 
  }
  function HandleBuyTicket(){
    setShoppingModal(false)
    setBuyticket(true)
  }
  function ReturnToSelectPassenger(){
    setActivateShoppingCard(false);
    setSelectTripModal(true)
    setWithAccStyle(true)
    setHome("active")
    setwithoutReg(true)
    setShoppingCart("");

    // setShowSelectPassenger(true)
  }
  function ReturnToActivateShoppingChart(){
    setActivateShoppingCard(true)
    setShoppingModal(false)
  }
  const formatDate = (dateString) => {
    // Splitting the date string into parts
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // month in Date object starts from 0
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  }
  function HandleAddNewTicket(){
    setSelectTripModal(true)
    setActivateShoppingCard(false)
  }


// console.log("payload",payload);
 
function HandlePayment(){

  setShowPay(false)
  setSelectTripModal(true)
  // setPaymentProcessing(true)
  // setBuyticket(false)
  // setActivateShoppingCard(false)
  if(paymentData.email.trim()  !== paymentData.confirmEmail.trim()){
    alert("please confirm you entered your email correctly")
  }else{

  
  if( paymentData.email !== "" && paymentData.confirmEmail !=="" && paymentData.firstName !=="" && paymentData.lastName !==""){
    ApiInstance.start_payment("icadpay", paymentData.email, paymentData.naration,
    (data) =>
    {
        let pgwname = data['pgwname'];
        let pmsid = data['pmsid'];
        let pmtref = data['pmtref'];
        setTransactionREf(pmtref)
        console.log(TransactionREf)
        // setPaymentData({...paymentData, Ref:pmtref,})
        const payload = {
          key: 'live_NDNlMGM5ZjJlYzliZTBmYjNiMjgzOGFlNzkyZGQ4MTBjZDFmMjQ2YjYzZWRhMmNkYjJhOGMwYWNjZTA3MDJmNA', // this is not a demo key. 
          // key: 'test_MDE0OTY1Y2NjNDI0MjIyZjY1ZWYwOWQ1YzkyMmJjZTZkYzlhZDBiZDVkOTg5ZDBmZjllYTMyMzVjNTI4MmJmMQ', // this is a demo key.  
          // key: 'test_YzA1MmNmYzE0OTc1Y2QyM2ViNWUwNTIxOGMzZjA2MjI5N2M4ZDU3YmY5ZDg1ZmU1OWIwNWE1NDU0YjkzYTZkOQ', // this is a demo key.     
          email: paymentData.email, // customer email 
          amount: parseFloat(paymentData.amount), // amount to be processed
          currency: "NGN", // currency
          first_name: paymentData.firstName,
          last_name: paymentData.lastName,
          phone_number: paymentData.PhoneNumber, // customer's phone number (optional)
          customerId: paymentData.email,
          ref: pmtref,
           // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
          narration: paymentData.firstName + paymentData.lastName,
          isBill:true,
          service_bill: "",
          callback_url: "https://nrc-icad.txware.com/", // specified redirect URL (potional)
          // callback_url: '', // specified redirect URL (potional)
          callback: (response) => {
              console.log(response);
          },
          onSuccess: (response) => {
            console.log(response);
            ApiInstance.confirm_payment("icadpay", response.merchantTransactionRef, response.transactionRef, response,
              (data) => { console.log( "this is the confirmation of payment:",data)
            
              setShowPay(false)
              // setPaymentProcessing(true)
              setBuyticket(false)
              setActivateShoppingCard(false)
              setPaymentProcessing(true)
              setSelectTripModal(true)
            //  setTimeout(() => {
            //   setPaymentProcessing(false)
            //   setpurchacedTicket(true)
            //   setShowPay(false)
              
            //   if(SessionSateData === "PURCHASED"){
            //    setPaymentProcessing(false)
            //     setSuccessfulPurchase(true)
            //     ApiInstance.get_last_purchase((purchase)=>{
            //       setpurchacedTicket(purchase)
            //       localStorage.setItem('purchasedTicket',JSON.stringify(purchase));
            //       console.log("ticket purchase =", purchase)
            //       setTimeout(()=>{
            //         setSuccessfulPurchase(false)
            //        setShowSingleTicket(true)
            //       },2000)
            //     })
            //   }
            //  }, 5000);
            
            
            
            });
              
              // const ref = response
              // localStorage.set('transactionResponse',response )
      
              // getValue(response.merchantTransactionRef, response.reference)   
              // if(setpower && type === 'prepaid'){
              //     doGetElectricValue(transactionId)                    
              // } else{
              //     // console.log(name)
              //     // if(name === 'SUCCESS'){
              //     //     setTransactionRefId(ref);
              //     //     getValue(ref);
              //         // console.log('status: ',status);
                      
              //         setModalMsg("successful");
              //         setmodal(true);
              //     //     console.log(fixed);
              //     // }
                  
              // }
              
          },
          onError: (response) => {
            console.log(response);
            alert("ticket purchase failed..")
            ApiInstance.cancel_payment("icadpay", pmtref);
            // setModalMsg("not successful");
          },
          onClose: () => {
            ApiInstance.cancel_payment("icadpay", pmtref);
              // alert('window closed');
          }
      }
      async function iCadPay() {
        const handler =await window.IcadPay.setup(payload)
        
        console.log(handler);
        };
        iCadPay() 
      })
      
     
  }else{
    alert("Please check and make sure all fields are filled properly")
  }
    // if(paymentData.ref !== ""){
     
    // }
  }
}
  // function iCadPay() {
  // const form = document.getElementById("payment-form");
  // const handler = window.IcadPay.setup({

  //     key: 'live_YzM1ODg3YzY5MWVjZjFlYzhkOTQxMDU3NmMzM2NlYjc4YzQwYTU1M2ZkZjRmNjI5ZjQzOGQzZmM4ZmY3NzZmYQ', //this is a demo key.
  //     // key: 'test_ZTM2NTNmYWU5ZWE0ZWZhZTQ5MTA3ZjIyZGI4MTQ4Zjc2N2FkZGRmZjg4NTUzMzBiMTljODBlZDgzYjc4MWI1Yw', //this is a demo key.
  //     email: document.getElementById("email").value, // customer email 
  //     amount: document.getElementById("amount").value,  // amount to be processed
  //     currency: "NGN", // currency
  //     first_name: document.getElementById("firstName").value, 
  //     last_name: document.getElementById("lastName").value,
  //     phone_number: document.getElementById("phone").value, // customer's phone number (optional)
  //     customerId: document.getElementById("email").value,
  //     ref: ` SP${Math.floor((Math.random() * 1000000000) + 111111)}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
  //     narration: document.getElementById("narration").value,
  //     callback_url: 'https://goal.com', // specified redirect URL (optional)
  //     meta: { // optional parameters
  //         consumer_id: 'data.customer_id',
  //         item_ref: 'payment.res',
  //         },
  //         callback: (response) => {
  //         console.log(response);
  //         },
  //     onSuccess: (response) => {
  //         console.log(response);
  //         // alert(`success. transaction ref is ${response.paymentReference}`);
  //         // alert('Success');
  //     },
  //     onError: (response) => {
  //         console.log(response);
  //         alert('Error');
  //     },
  //     onClose: () => {
  //         console.log('window closed');
  //         // alert('window closed');
  //     }
  // });
  // }
  // document.getElementById("qrCode").innerHTML =purchasedticket.ticketlist[0].qrcode
  function CallCHeckout(){
    setShowPay(true);
    setActivateShoppingCard(false)
  }
  const HandleDeleteTicket = async (biunique) => {
    
    try {
        // Perform deletion operation
       let result = await ApiInstance.del_ticket(biunique);
        alert("Ticket deleted successfully")
      console.log("results =",JSON.stringify(result));
        // After deletion, reload shopping basket
        const shoppingBasket = await  ApiInstance.get_shopping_basket();
        const initialShoppingCartBasket= [...shoppingCartBasket]
        const CurrentShoppingCartBasket = initialShoppingCartBasket.filter(item => item.biunique !== biunique)
        // Update state with new shopping basket data
        setShoppingCartBasket(CurrentShoppingCartBasket);
        let currentTotalAmount = 0;
        if(CurrentShoppingCartBasket.length){
          currentTotalAmount = CurrentShoppingCartBasket.reduce((total, current)=>{
            return total += current.centprice/100
          },0)
        }
        // let data = ApiInstance.get_shopping_basket_amount() /100
        let dataa =ApiInstance.get_shopping_basket_amount()
        console.log("tickets amount =",currentTotalAmount)
        setTotalPrice(currentTotalAmount)
        setCartTotalPrice(currentTotalAmount)
        // updatePrice();
      
    } catch (error) {
        console.error("Error deleting ticket:", error);
    }
    // updatePrice()
};
//   function HandleDeleteTicket(biunique){
//     ApiInstance.del_ticket(biunique);
//       let shoppingBasket = ApiInstance.get_shopping_basket();
//       console.log(shoppingBasket);
//       setShoppingCartBasket(shoppingBasket);
//       checkBasket()

// }





  return (
    <nav>
      <div className="logo">
        <img
          src="WhatsApp_Image_2024-02-27_at_18.48.01_3d5745bc-removebg-preview.png"
          alt=""
          width={60}
        />
      </div>
      <div>
        <ul>
          <li className={home} onClick={ActivateHome}>
            Home
          </li>
          <li className={shoppingCart} onClick={ActivateShoppingCart}>
            Shopping Cart
          </li>
          <li className={information} onClick={ActivateInformation}>
            Information
          </li>
          <li className={purchaseHistory} onClick={ActivatePurchaseHistory}>
            Purchase History
          </li>
          <li className={signIn} onClick={ActivateSignIn}>
           {isLoggedIn?"Log out": "Sign in"}
          </li>
        </ul>
      </div>
      {informationModal ? (
        <div  className="container-for-modals">

        <div className="desktop-information-modal">
          <div className="cancel-desktop">
            <h2 onClick={cancelInformationModal}>X</h2>
          </div>
          <h2 className="info">Information</h2>
          <div className="wel-desktop">
            <p>
              Welcome to the official Port Harcourt - Aba e-ticketing platform
              provided by the Nigerian Railway Corporation in cooperation with
              its technical partners.
            </p>
            <p>
              We are constantly working to improve our service for you and will
              therefore update this platform on regular intervals.
            </p>
            <p>
              Please note that this application features free seating only on
              all journeys due to NRC's policy.
            </p>
            <p>
              For more information please visit the official NRC website
              https://nrc.gov.ng .
            </p>
            <p>
              We hope you will enjoy a smooth booking process with this
              application and wish you a pleasant trip.
            </p>
          </div>
        </div>
        </div>
      ) : null}
     {purchaseModal?
     <div  className="container-for-modals">

      <div className="pruchase-desktop-modal">
        <div className="cancel-desktop">
          <h2 onClick={cancelPurchaseModal}>X</h2>
        </div>
        <h2 className="info purchase-info">Purchase History</h2>
        <div className="purchase-container">
          <div>
            <h2>New Ticket</h2>
            <div style={{ display: "flex", gap: "30px" }}>
              <p>Qty</p> <p>Booking</p> <p>Email</p> <p>NIN</p>
            </div>
            <div >
             {purchasedticket !== null? <div  className="new-Area"><p>1</p><p className="purchase-location">{purchasedticket?.ticketlist[0].stid1_name} - {purchasedticket?.ticketlist[0].stid2_name} {formatDate(purchasedticket?.ticketlist[0].texpiry)} 15:00 - 16:15 Train No. 246 Free Seating Naira 200,- </p></div>:<div  className="new-Area"></div>}
            </div>
            <div className="left-download-ticket-container">
           {purchasedticket !== null? <a href={purchasedticket?.ticketlist[0].url}><button>Download <br />ticket</button></a>:<button>Download <br />ticket</button>}
              <button onClick={()=>{
                if(purchasedticket){
                  setPurchaseModal(false)
                  setShowSingleTicket(true)
                }else{
                  return false
                }
               
              }}>Show<br />ticket</button>
            </div>
            <div><p>Please note that your ticket has been sent to your <br /> email address as well.</p></div>
          </div>
          <div>
            <h3>Previous Tickets</h3>
            <div style={{ display: "flex", gap: "30px" }}>
              <p>Qty</p> <p>Booking</p> <p>Email</p> <p>NIN</p>
              
            </div>
            <div className="left-container-purchase">
            <div >
                {purchasedticket !== null?<div className="area-right"><p>1</p> <p className="purchase-locaion-right">{purchasedticket?.ticketlist[0].stid1_name} - {purchasedticket?.ticketlist[0].stid2_name} {formatDate(purchasedticket?.ticketlist[0].texpiry)} 15:00 - 16:15 Train No. 246, Free Seating Naira 200,-</p></div>:null}
              </div>
             
            </div>
            <div className="left-download-ticket-container">
            {purchasedticket !== null? <a href={purchasedticket.ticketlist[0].url}><button>Download <br />ticket</button></a>:<button>Download <br />ticket</button>}
              <button onClick={()=>{
                if(purchasedticket){
                  setPurchaseModal(false)
                  setShowSingleTicket(true)
                }else{
                  return false
                }
               
              }}>Show<br />ticket</button>
            </div>
          </div>
        </div>
      </div>
     </div>
      :null}
      {signinModal ? (
        <div  className="container-for-modals">
        <div className="signin-desktop">
          <div className="cancel-desktop">
            <h2 onClick={closeSignin}>X</h2>
          </div>
          {showSignin ? (
            <div>
              <h2 className="info  purchase-info signin-data">Sign in</h2>
              <div className="signin-desktop-container">
                <h5>Please type in your email and password to sign in.</h5>
                <p className="lab-email">please type your email.</p>
                <input
                  id="input1"
                  type="email"
                  value={userEmail}
                  placeholder="example@gmail.com"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                {error && userEmail === "" ? (
                  <span>this field is required</span>
                ) : null}

                <p className="lab-email">Please type in your password</p>
                <input
                  id="input2"
                  type="password"
                  value={userPassword}
                  placeholder="*************"
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                {error && userPassword === "" ? (
                  <span>this field is required</span>
                ) : null}

                <p className="pls-clk" onClick={HandleRegister}>
                  Please click here if you are not a registered user yet
                </p>
                <p className="pls-clk" onClick={handleForgottenPassword}>
                  Please click if you have forgotten your password.
                </p>
                <p className="pls-clk-go">please click "GO" to continue</p>
                <h1 className={gobtnSigninz}  onClick={handleSend}>
                  GO
                </h1>
              </div>
            </div>
          ) : null}

          {forgotpasswordModal?<div>
            <h2 className="info purchase-info">Forgot Password?</h2>
            <div className="forgotten-password-cotainer">
              <h5>please type in your registered email.</h5>
              <p>your email</p>
              <input type="text" value={forgotpwdinput} placeholder="test@test.com" onChange={(e)=>setForgotpwdinput(e.target.value)}/>
              {error && forgotpwdinput === ""? <p className="err-msg">this field is required</p>:null}
              <p className="pls-clk-go">please click "GO" to continue</p>
              <h1 className={goForgottenpwd} id="go-btn" onClick={handlefogottenpwdBtn}>
                GO
              </h1>
            </div>
          </div>:null}

          
            <div className="Resgister-user">
             {showRegister? <div>
                <h2 className="info  purchase-info rgister-lab">
                  My Registration
                </h2>
                <div className="register-user-container">
                  <h5>Please fill all required infromation and click GO</h5>
                  <div  className="register-container"style={{ display: "flex" }}>
                    <div>
                      <div className="register-sec-1">
                        <div>
                          <label htmlFor="" className="title-label">
                            title
                          </label>
                          <input
                            className="title"
                            type="text"
                            placeholder="Mr"
                            value={registerUserData.title}
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                title: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.title === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            First Name
                          </label>
                          <input
                            className="firstname-input"
                            type="text"
                            value={registerUserData.firstName}
                            placeholder="emmanuel"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                firstName: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.firstName === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Surname
                          </label>
                          <input
                            className="firstname-input"
                            value={registerUserData.surName}
                            type="text"
                            placeholder="yakubu"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                surName: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.surName === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="register-sec-1">
                        <div>
                          <label htmlFor="" className="title-label">
                            No.
                          </label>
                          <input
                            className="title"
                            type="text"
                            value={registerUserData.No}
                            placeholder="12"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                No: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.No === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Landmark
                          </label>
                          <input
                            className="firstname-input"
                            type="text"
                            value={registerUserData.road}
                            placeholder="unity square"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                road: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.road === ""? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            City
                          </label>
                          <input
                            className="firstname-input"
                            type="text"
                            value={registerUserData.city}
                            placeholder="Anyigba"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                city: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.city === ""? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="register-sec-1">
                        <div>
                          <label htmlFor="" className="title-label">
                            State
                          </label>
                          <input
                            type="text"
                            className="state"
                            value={registerUserData.state}
                            placeholder="kogi"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                state: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.state ==="" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Country
                          </label>
                          <input
                            type="text"
                            className="state"
                            value={registerUserData.country}
                            placeholder="Nigeria"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                country: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.country === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Telephone
                          </label>
                          <input
                            type="text"
                            className="telphone"
                            value={registerUserData.telephone}
                            placeholder="+234 8144069 XXX"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                telephone: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.telephone === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="register-sec-1 reg-email">
                        <div>
                          <label htmlFor="" className="title-label">
                            Your Email
                          </label>
                          <input
                            type="text"
                            className="reg-pwd"
                            value={registerUserData.email}
                            placeholder="emmanuel@emma.com"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                email: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.email === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Please confrim your email.
                          </label>
                          <input
                            type="text"
                            className="reg-pwd"
                            value={registerUserData.confirmEmail}
                            placeholder="emmanuel@emma.com"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                confirmEmail: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.confirmEmail === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="register-sec-1">
                        <div>
                          <label htmlFor="" className="title-label">
                            Your Password
                          </label>
                          <input
                            type="password"
                            value={registerUserData.password}
                            className="reg-pwd"
                            placeholder="**********"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                password: e.target.value,
                              })
                            }
                          />
                          {registerError  && registerUserData.password === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="" className="title-label">
                            Please confrim your password.
                          </label>
                          <input
                            type="password"
                            value={registerUserData.confirmPassword}
                            className="reg-pwd"
                            placeholder="**********"
                            onChange={(e) =>
                              setRegisterUserData({
                                ...registerUserData,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                          {registerError && registerUserData.confirmPassword === "" ? (
                            <p className="reg-err">this field is required</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="register-option">
                      <button type="button" className="fill-data-btn" id="fill-data">
                        Fill in <br />
                        Data
                      </button>
                      <button type="button"  id="fill-nin" onClick={HandleRegisterUser}>
                        Fill in <br />
                        NIN
                      </button>
                      <button type="button">
                        Confrim <br />
                        Data
                      </button>
                    </div>
                  </div>
                  <div className="go-register">
                    <p className="pls-clk-go">please click "GO" to continue</p>
                    <h1 className={registerBtn} id="go-register" onClick={HandleRegisterUser}>
                      GO
                    </h1>
                  </div>
                </div>
              </div>:null}

            {showNin?  <div>
                <h2 className="info  purchase-info nin-reg">My Registration</h2>
                <div className="nin-verification">
                  <h5>Please fill in all required information and click GO</h5>

                  <div className="nin-container">
                    <div className="nin-details">
                      <p>Customer Number:</p>
                      <p>NIN:</p>
                    </div>
                    <div className="nin-details">
                      <p>not yet completed</p>
                      <p>not yet completed</p>
                      <p>Mr david</p>
                      <p>email@email.com</p>
                      <p>roadroad roadnumber</p>
                      <p>Citycitycity</p>
                      <p>statestatestate</p>
                      <p>country</p>
                      <p>+23408980902930</p>
                    </div>
                    <div className="register-option">
                      <button id="your-data" type="button">
                        Your <br />
                        Data
                      </button>
                      <button id="your-nin" type="button">
                        Fill in your
                        <br />
                        NIN
                      </button>
                    </div>
                  </div>
                  <div className="nin-field">
                    <label htmlFor="">Please type in your NIN</label>
                    <input type="text"  value={ninDetails} onChange={(e)=>setNinDetails(e.target.value)}/>
                    {ninError && ninDetails === ""?<p>this field is required.</p>:null}
                  </div>

                  <div>
                    <p className="pls-clk-go">please click "GO" to continue</p>
                    <h1 className={goNinbtn} id="gonin" onClick={HandleNIn}>
                      GO
                    </h1>
                  </div>
                </div>
              </div>:null}

           { failedNin?  <div>
              <div
                    className="failed-nin-verification"
            
                  >
                    <div className="failed-container">
                      <div>
                        <h1 className="failed-sign">X</h1>
                      </div>
                      <div>
                        <h1 className="please-check">Please check</h1>
                        <h3>Your NIN has not been verified.</h3>
                        <p>you will be redirected to the NIN page.</p>
                      </div>
                    </div>
                  </div>
              </div>:null}
                  {successNin?<div>
                    <h2 className="info  purchase-info">My Registration</h2>
                    <div className="successful-nin">
                    <div className="successful-nin-container">
                      <div>
                        <h1 className="successful-sign">
                          <GiCheckMark />
                        </h1>
                      </div>
                      <div>
                        <h1 className="please-check">Successful</h1>
                        <h3>
                          Your NIN has been verified <br />
                          Your account has been created
                        </h3>
                        <p>you will be redirected to the account page.</p>
                      </div>
                    </div>
                  </div>
                  </div>:null}

              <div style={{display:"none"}}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="my-account">
                  <h4>User information</h4>
                  <div className="nin-container">
                    <div className="nin-details">
                      <p>Customer Number:</p>
                      <p>NIN:</p>
                    </div>
                    <div className="nin-details">
                      <p>not yet completed</p>
                      <p>not yet completed</p>
                      <p>Mr david</p>
                      <p>email@email.com</p>
                      <p>roadroad roadnumber</p>
                      <p>Citycitycity</p>
                      <p>statestatestate</p>
                      <p>country</p>
                      <p>+23408980902930</p>
                    </div>
                    <div className="my-account-option">
                      <button id="" type="button">
                        Change
                        <br />
                        Password
                      </button>
                      <button id="" type="button">
                        Change
                        <br />
                        Email
                      </button>
                      <button type="button">
                        Change
                        <br />
                        Data
                      </button>
                      <button id="sign-out" type="button">
                        Sign Out
                      </button>
                      <button id="delete-acount" type="button">
                        Delete
                        <br />
                        Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="my-account">
                  <h4>User information</h4>
                  <div className="myaccount-cont">
                    <div className="change-password-contianer">
                      <div>
                        <label htmlFor="">
                          Please type in your old password
                        </label>
                        <input type="text" placeholder="***********" />
                      </div>
                      <div>
                        <label htmlFor="">
                          Please type in your new password
                        </label>
                        <input type="text" placeholder="***********" />
                      </div>
                      <div>
                        <label htmlFor="">
                          Please confirm your new password
                        </label>
                        <input type="text" placeholder="***********" />
                      </div>
                      <p className="pls-clk-go">
                        please click "GO" to continue
                      </p>
                      <h1 className="go-purchase" id="go-btn">
                        GO
                      </h1>
                    </div>
                    <div>
                      <div className="my-account-option">
                        <button id="change-password" type="button">
                          Change
                          <br />
                          Password
                        </button>
                        <button id="" type="button">
                          Change
                          <br />
                          Email
                        </button>
                        <button type="button">
                          Change
                          <br />
                          Data
                        </button>
                        <button id="sign-out" type="button">
                          Sign Out
                        </button>
                        <button id="delete-acount" type="button">
                          Delete
                          <br />
                          Account
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="successful-password"
                    style={{ display: "none" }}
                  >
                    <div className="successful-nin-container">
                      <div>
                        <h1 className="successful-sign-pwd">
                          <GiCheckMark />
                        </h1>
                      </div>
                      <div>
                        <h1 className="please-check">Successful</h1>
                        <h3>
                          Your password has been <br />
                          successfully changed.
                        </h3>
                        <p>you will be redirected to the account page.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="changedata">
                  <h4>User information</h4>
                  <div className="changeData-container">
                    <div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <div className="register-sec-1">
                              <div>
                                <label
                                  htmlFor=""
                                  className="title-label-userData"
                                >
                                  title
                                </label>
                                <input
                                  id="title-userData"
                                  type="text"
                                  placeholder="Mr"
                                  value={"Mr."}
                                  disabled
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor=""
                                  className="title-label-userData"
                                >
                                  First Name
                                </label>
                                <input
                                  className="firstname-input"
                                  id="firstname-userData"
                                  type="text"
                                  value={"Daniel"}
                                  disabled
                                />
                              </div>
                              <div>
                                <label htmlFor="" className="title-label">
                                  Surname
                                </label>
                                <input
                                  className="firstname-input"
                                  type="text"
                                  placeholder="surname"
                                />
                              </div>
                            </div>
                            <div className="register-sec-1">
                              <div>
                                <label htmlFor="" className="title-label">
                                  No.
                                </label>
                                <input
                                  className="title"
                                  type="text"
                                  placeholder="house number"
                                />
                              </div>
                              <div>
                                <label htmlFor="" className="title-label">
                                  Landmark
                                </label>
                                <input
                                  className="firstname-input"
                                  type="text"
                                  placeholder="closest bus station"
                                />
                              </div>
                              <div>
                                <label htmlFor="" className="title-label">
                                  City
                                </label>
                                <input
                                  className="firstname-input"
                                  type="text"
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="register-sec-1">
                              <div>
                                <label htmlFor="" className="title-label">
                                  State
                                </label>
                                <input
                                  type="text"
                                  className="state"
                                  placeholder="state"
                                />
                              </div>
                              <div>
                                <label htmlFor="" className="title-label">
                                  Country
                                </label>
                                <input
                                  type="text"
                                  className="state"
                                  placeholder="Country"
                                />
                              </div>
                              <div>
                                <label htmlFor="" className="title-label">
                                  Telephone
                                </label>
                                <input
                                  type="text"
                                  className="telphone"
                                  placeholder="+234 xxx xxx xxx"
                                />
                              </div>
                            </div>
                            <p className="pls-clk-go">
                              please click "GO" to continue
                            </p>
                            <h1 className="go-purchase" id="go-btn">
                              GO
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="my-account-option">
                        <button type="button">
                          Change
                          <br />
                          Password
                        </button>
                        <button id="" type="button">
                          Change
                          <br />
                          Email
                        </button>
                        <button id="change-password" type="button">
                          Change
                          <br />
                          Data
                        </button>
                        <button id="sign-out" type="button">
                          Sign Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="successful-account-update">
                  <div className="successful-nin-container">
                    <div>
                      <h1 className="successful-sign">
                        <GiCheckMark />
                      </h1>
                    </div>
                    <div>
                      <h1 className="please-check">Successful</h1>
                      <h3>
                        Your NIN has been verified <br />
                        Your account has been created
                      </h3>
                      <p>you will be redirected to the account page.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="signout">
                  <h5>Are you sure you want to go?</h5>
                  <div className="signout-cont">
                    <div className="signout-container">
                      <button>Yes</button>
                      <button>No</button>
                      <p className="pls-clk-go">
                        please click "GO" to continue
                      </p>
                      <h1 className="go-purchase" id="go-btn">
                        GO
                      </h1>
                    </div>
                    <div>
                      <div className="my-account-option">
                        <button type="button">
                          Change
                          <br />
                          Password
                        </button>
                        <button id="" type="button">
                          Change
                          <br />
                          Email
                        </button>
                        <button type="button">
                          Change
                          <br />
                          Data
                        </button>
                        <button id="sign-out-sign-out" type="button">
                          Sign Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2 className="info  purchase-info">My Account</h2>
                <div className="successful-signout">
                  <div className="successful-signout-user">
                    <div>
                      <h1 className="successful-sign-out">
                        <GiCheckMark />
                      </h1>
                    </div>
                    <div>
                      <h1 className="please-check">Goodbye!</h1>
                      <h3>Have a nice day</h3>
                      <p>you will be redirected to the account page.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
        
            {/* change email message */}
          {showChangeemailsucc?<div >
            <h2 className="info  purchase-info">Sign in</h2>
            <div className="succesfull-login">
              <div>
                <div className="successmark-email">
                  <GiCheckMark />
                </div>
              </div>
              <div>
                <p className="wel-login">Succesful</p>
                <p className="username-login">
                  An Email has been sent to your
                </p>
                <p className="username-login">account.</p>

                <h5>
                  please check your email account and follow the instructions to
                  reset your password.
                </h5>
              </div>
            </div>
          </div>:null}
          {loginSuccesfully ? (
            <div className="success-login">
              <h2 className="info  purchase-info">Sign in</h2>
              <div className="succesfull-login">
                <div>
                  <div className="successmark">
                    <GiCheckMark />
                  </div>
                </div>
                <div>
                  <p className="wel-login">Welcome</p>
                  <p className="username-login">Mr. David </p>
                  <p className="username-login">to your account</p>

                  <h5>
                    Your account will accelerate any booking or <br />
                    purchase process - enjoy your trip.
                  </h5>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        </div>
      ) : null}

       {shoppingModal? <div className="shoppingChartmodal">
        <div className="cancel-desktop">
          <h2 onClick={cancelshoppingChartModal}>X</h2>      
        </div>
        <h2 className="info  purchase-info shopping-title">Shopping Cart</h2>
        <div className="shopping-top">Booking &gt; Shopping Cart &gt; Buy &gt;<p>Ticket</p></div>
        <div className="shoppingchartContainer">
          <h5>Please type in your NIN and email to continue.</h5>
          <div className="shopping-nin-container">
            <div>
              <label htmlFor="">Please type in your NIN.</label>
              <input type="text" placeholder=" xxxx xxxx xxxx"  value={shoppingChartNin.nin} onChange={(e)=>setShoppingChartNin({...shoppingChartNin, nin:e.target.value})}/>
            </div>
            <div>
              <label htmlFor="">Please type in my email</label>
              <input type="text" placeholder="email@email.com" value={shoppingChartNin.email} onChange={(e)=>setShoppingChartNin({...shoppingChartNin, email:e.target.value})} />
            </div>
            <div>
              <label htmlFor="">Please confirm your email.</label>
              <input type="text" placeholder="email@email.com" value={shoppingChartNin.confirmEmail} onChange={(e)=>setShoppingChartNin({...shoppingChartNin, confirmEmail:e.target.value})}/>
            </div>
           <h4> Or continue with your registered account?</h4>
          </div>

        </div>
        <div className='sel-mb'>
                <h4 className='please-go-desk'>Please click "GO" to continue</h4>
                <div className='go-shopping-chart'> <button id='prev-btn-shopping-chart' onClick={ReturnToActivateShoppingChart}> <MdArrowBackIos /> </button><h1 className={goBtnshoppingNin} onClick={HandleBuyTicket}>GO</h1> <button><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
            </div>:null}
       {paymentProcessing?<div className="container-for-modals">

<div className="shoppingChartmodal shopping-main">
<div className="cancel-desktop">
<h2 onClick={cancelshoppingChartModal}>X</h2>      
</div>
<h2 className="info chart-tit  purchase-info shopping-title">Shopping Cart</h2>
<div className="shopping-top">Booking &gt; Shopping Cart &gt; Buy &gt;<p>Ticket</p></div>
<div className="wait-container">
<div className="please-wait"><p>Please</p> <span>Wait...</span></div>
<div className="processing-order">  
<p>... while we are processing <br /> your order...</p>
</div>
</div>
</div>
              </div>:null}
            
       {successfulPurchase? <div className="container-for-modals">

        <div className="shoppingChartmodal shopping-main"  >
        <div className="cancel-desktop">
          <h2 onClick={cancelshoppingChartModal}>X</h2>      
        </div>
        <h2 className="info chart-tit  purchase-info shopping-title">Shopping Cart</h2>
        <div className="shopping-top-ticket">Booking &gt; Shopping Cart &gt; Buy &gt;Ticket</div>
        <div className="wait-container purchase-sucessfull">
            <div className="purchase-mark"><GiCheckMark/></div>
            <div className="thanks-for-purchase"><span>Thank you <p>for</p></span>  <p> your purchase.</p></div>
            <div className="forwarded"><p>you will  be forwarded to your purchase tickets <br /> within the next ten seconds, or click this link.</p></div>
        </div>

        </div>
        </div>:null}
        { activateShoppingCard?
        <div className="container-for-modals">

           <div className="shopping-main">
        <div className="cancel-desktop">
          <h2 onClick={cancelshoppingChartModal}>X</h2>      
        </div>
        <h2 className="info chart-tit  purchase-info shopping-title">Shopping Cart</h2>
        <div className="shopping-top">Booking &gt; Shopping Cart &gt; <p>Buy &gt;</p><p>Ticket</p></div>
        <div className="chart-container">
        <div className="curr-bookin"><h2>Current Booking</h2> <p className="add-to-chart" style={{cursor:"pointer"}} onClick={HandleAddNewTicket}>+</p></div>
          <div className="qty-booking"><h2> Qty</h2> <h2>Booking</h2></div>
          <div className="qty-booking-list">
            {shoppingCartBasket?.map((data)=>(
                <div className="flex qty-booking-list-content">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <div style={{fontSize:"25px"}}>{data.count}</div>
                  <div><AiTwotoneDelete style={{fontSize:"25px"}} onClick={()=>HandleDeleteTicket(data.biunique)}/></div>
                </div>
                <div key={data.biunique}>
                  <p style={{fontSize:"20px"}}>{stations[data.route.stiddep]} - {stations[data.route.stidarr]} <br />{formatDate(data.route.leg[0].lxdate)}, Train No. 264, Free Seating,<br />Price  ₦ {data.centprice/100}.00</p>
                </div>
              </div>
            ))}
          

           
          </div>
        </div>
       <div className="totalprice"> <p className="totalpriceP">Total Price: <span style={{fontSize:"25px"}}> ₦ {TotalPrice}.00</span></p></div>
       <div className="cont-purchase" ><p>CONTINUE PURCHASE</p>
        <div><button onClick={()=>{setwithoutReg(false)
        setWithAccStyle(true);setIspleaseContinue(true)
        setGoshoppingCartStyle(false)}} className={withoutReg?"withoutreg-style":"withoutreg-style1"}>Without registering?</button><button className={withAccStyle?"withoutreg-style":"withoutreg-style1"} onClick={()=>{
          // setActivateShoppingCard(false)
          setIspleaseContinue(true)
        if(isLoggedIn=== false){
          setwithoutReg(true)
          setWithAccStyle(false)
          setGoshoppingCartStyle(false) 
        }else{
          setwithoutReg(true)
          setWithAccStyle(false)
          setGoshoppingCartStyle(false) 
        }

          
        }}>{isLoggedIn?"Continue":"With an account?"}</button></div>
        </div>



        {/* <div className='sel-mb'>
                <h4 className='please-go-desk'>Please click "GO" to continue</h4>
                <div className='go-shopping-chart'> <button id='prev-btn-shopping-chart' onClick={ReturnToSelectPassenger}> <MdArrowBackIos /> </button><h1 id='go-mb' className='sect-go'>GO</h1> <button><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
       <div className="cont-purchase cont-purchase-verify"><p>Payment Provider?</p>
        <div ><button onClick={CallCHeckout}>ICADPAY</button></div>
        </div> */}



        <div className='sel-mb'>
                {ispleaseContinue?<h4 className='please-go-desk' style={{marginTop:"10px",marginBottom:"5px"}}>Please click "GO" to continue</h4>:null}
                <div className='go-shopping-chart'> <button id='prev-btn-shopping-chart' onClick={ReturnToSelectPassenger}> <MdArrowBackIos /> </button><h1   className={GoshoppingCartStyle?'sect-goCart':'sect-goactive'} onClick={WithoutRegistering}>GO</h1> <button><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
        </div>
        </div>
        :null}
       {BuyTicket?
       <div className="container-for-modals">
         <div className="shoppingChartmodal shopping-main">
        <div className="cancel-desktop">
          <h2 onClick={cancelshoppingChartModal}>X</h2>      
        </div>
        <h2 className="info chart-tit  purchase-info shopping-title">Shopping Cart</h2>
        <div className="shopping-top">Booking &gt; Shopping Cart &gt; <p>Buy &gt;</p><p>Ticket</p></div>
        <div className="chart-container">
          <div className="curr-bookin"><h2>Current Booking</h2> <p className="add-to-chart">+</p></div>
          <div className="qty-booking"><h2> Qty</h2> <h2>Booking</h2></div>
          <div className="qty-booking-list">
          {shoppingCartBasket?.map((data)=>(
                <div className="flex qty-booking-list-content">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <div style={{fontSize:"25px"}}>{data.count}</div>
                  <div><AiTwotoneDelete style={{fontSize:"25px"}} onClick={()=>{HandleDeleteTicket(data.biunique);
                  // setTotalPrice(ApiInstance.get_shopping_basket_amount() / 100);
                  // setCartTotalPrice(ApiInstance.get_shopping_basket_amount() / 100)
                  }}/></div>
                </div>
                <div key={data.biunique}>
                  <p style={{fontSize:"20px"}}>{stations[data.route.stiddep]} - {stations[data.route.stidarr]} <br />{formatDate(data.route.leg[0].lxdate)}, Train No. 264, Free Seating,<br />Price  ₦ {data.centprice/100}.00</p>
                </div>
              </div>
            ))}
          
          
           
          </div>
        </div>
        <div className="totalprice totalprice-buyTicket"> <p className="totalprice-buyTicket-p" >Total Price: < span> ₦ {TotalPrice}.00</span></p></div>
        <div className="cont-purchase cont-purchase-verify "><p>Choose Your Payment Provider?</p>
        <div ><button onClick={()=>setchooseIcad(false)} className={chooseIcad?"cont-purchase-verify-btn":"cont-purchase-verify-btnActive"}>ICADPAY</button></div>
        </div>



        <div className='sel-mb'>
                <h4 className='please-go-desk' style={{marginTop:"10px",marginBottom:"-10px"}}>Please click "GO" to continue</h4>
                <div className='go-shopping-chart'> <button id='prev-btn-shopping-chart'> <MdArrowBackIos onClick={returnBack1} /> </button><h1  className={chooseIcad?'sect-goCart':"sect-goactive-buy "} onClick={CallCHeckout}>GO</h1> <button style={{marginBottom:"-60px"}}><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
        </div>
       </div>
        :null}
        {showPay?
        <div  className="container-for-modals">
        <div className="shoppingChartmodal shopping-main">
        <div className="cancel-desktop">
          <h2 onClick={()=>{
              setShowPay(false)
              setBuyticket(false)
              setActivateShoppingCard(false)
              setSelectTripModal(true)
              setBooking(false)
              setShoppingCart("")
              setHome("active")
             
            }}>X</h2>      
        </div>
        <h2 className="info chart-tit  purchase-info shopping-title">Payout </h2>
        <div className="payment_procedure">
      
          <form action="">
            <div>
            <label htmlFor="">Firstname</label>
            <input type="text" name="" id="firstName" value={paymentData.firstName} placeholder="please enter your Firstname.." onChange={(e)=>setPaymentData({...paymentData,firstName:e.target.value})} />
            </div>
            <div>
            <label htmlFor="">Lastname</label>
            <input type="text" name="" id="lastName"value={paymentData.lastName} placeholder="please enter your LastName..." onChange={(e)=>setPaymentData({...paymentData,lastName:e.target.value})}/>
            </div>
            <div>
            <label htmlFor="">Email</label>
            <input type="text" name="" id="email" value={paymentData.email} placeholder="please enter your Email.."onChange={(e)=>setPaymentData({...paymentData,email:e.target.value,amount:ApiInstance.get_shopping_basket_amount() /100})} />
            </div>
            <div>
            <label htmlFor="">Confirm Email</label>
            <input type="text" name="" id="phone" value={paymentData.confirmEmail} placeholder="please enter your Phone Number.."onChange={(e)=>setPaymentData({...paymentData,confirmEmail:e.target.value})} />
            </div>
            <div style={{display:"none"}}>
              <input type="text"id="amount"  value={TotalPrice} />
              <input type="text" id="narration" value={"NRC - ticket purchase"}/>
            </div>
            
           
            
           
          </form>
          <div>
          <div className='sel-mb payoutGoBtn'>
                <h4 className='please-go-desk please-go-payout'>Please click "GO" to continue</h4>
                <div className='go-payout'> <button id='prev-btn-shopping-chart'> <MdArrowBackIos onClick={()=>{
                  setBuyticket(true)
                  setShowPay(false)
                }} /> </button><h1 id='go-mb' className={payoutGo} onClick={HandlePayment}>GO</h1> <button style={{marginBottom:"-60px"}}><MdArrowForwardIos /></button></div>
                <div></div>
            </div>
          </div>
        </div>
        </div>
        </div>
        :null }
        
        {showSingletTicket?
        <div  className="container-for-modals">

        <div className="ticketReciept">
          <div className="cancelTicket" style={{cursor:"pointer"}} onClick={()=>{
            setShowSingleTicket(false);
            setPurchaseModal(true);
            ApiInstance.purchase_stored(purchasedticket.purchaseid)
          }}>X</div>
          <div className="ticket-modal" style={{marginTop:"-30px"}}>
            <div className="your-ticket-container"><div className="your-ticket"><h2>Your Ticket</h2> <p >X</p></div></div>
            <div className="qr-container">
            <div id="qrCode" ref={QrcodeRef}>
           
           </div>
            {/* <div className="click-Qr2"><p className="click-Qr-text-2">Click QR to lock your screen</p></div> */}
            </div>
            <div className="ticket-sub-title">
              <ul>
                <li>Qty</li>
                <li>booking</li>
                <li style={{marginLeft:"60px"}}>Email <GiCheckMark style={{color:"#00f600"}}/></li>
            
              </ul>
              <div className="ticket_detials">
                <div>
                  <h2>1</h2>
                </div>
                <div>
                  <h2>{purchasedticket.ticketlist[0].stid1_name} - {purchasedticket.ticketlist[0].stid2_name}</h2><br />
                  <p>{formatDate(purchasedticket.ticketlist[0].texpiry)}, 15:00 - 16:50</p>
                  <br />
                  <p style={{marginTop:"1px",marginBottom:"3px"}}>Train no 246, free seating</p>
                  <br />
                  Naira {purchasedticket.ticketlist[0].centprice /100}.00 
                </div>
              </div>
              <div className="tick-data">
                <div>
                  <ul>
                    <li>Number:</li>
                    <li>Code:</li>
                    <li>Verification:</li>
                    <li>Purchased:</li>
                    <li>Valid:</li>
                  </ul>
                </div>
                <div>
                <ul>
                    <li>{purchasedticket.ticketlist[0].tno}</li>
                    <li>{purchasedticket.ticketlist[0].tnocode}</li>
                    <li>{purchasedticket.ticketlist[0].tnocodechar}</li>
                    <li>{purchasedticket.ticketlist[0].tinception}</li>
                    <li>{purchasedticket.ticketlist[0].texpiry}</li>
                  </ul>
                </div>
              </div>
              <div className="tick-footer">
                Only ticket obtained via this app or at the counters <br />
                in the stations are guaranteed to be valid! 
              </div>
            </div>
          </div>

          
        </div>
        </div>
        :null}


            {/* css for this is only on the largest screen remember to move it to the other screen size */}
        <div  className="container-for-modals" style={{display:"none"}}>
          <div className="shoppingChartmodal shopping-main">
          <div className="cancel-desktop">
          <h2 onClick={cancelshoppingChartModal}>X</h2>      
        </div>
        <h2 className="info chart-tit  purchase-info shopping-title">Shopping Cart</h2>
        <div className="shopping-top">Booking &gt; Shopping Cart &gt; <p>Buy &gt;</p><p>Ticket</p></div>
           <div className="each-passenger-container">
            <div className="header-for-each"><h2>Please fill in all the details for every passenger below to continue.</h2>
            <p>There can be only one ticket issued per passenger journey.</p>
            </div>
           <div className="booking-for-each-passenger">
            <div style={{display:"flex",gap:"40px", color:"#00f600",marginBottom:"-25px"}}><h3>Qty</h3> <h3>Booking</h3> </div>
             <div style={{display:"flex", gap:"62px"}}>
              <div><h3>1</h3></div>
              <div ><h3>Aba - Konkon</h3><br />
              <div style={{display:"flex",alignItems:"center",marginTop:"-50px"}}>Sunday 11 february 2024, 15:00 to 16:15, Train No. 246, Free Seating, <span><h4>Niara 200</h4></span></div>
              <div><form action="">
                <div>
                  <label htmlFor=""><p>Please type in your first name</p></label>
                  <input type="text" />
                </div>
                </form></div>
              <div><form action=""></form></div>
              </div>
             </div>

             </div>
           </div>

          </div>
        </div>
      
        {/* <div className="ticketReciept_qr"></div> */}
   
    </nav>
  );
}

export default Nav;
